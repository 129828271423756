.sidebar{
  width: 280px;
  min-height: 100vh;
  box-shadow: 0px 4px 8px rgb(0 0 0 / 16%);
  background-image: linear-gradient(to bottom right,#5449e3,#39a6e3);
  position: fixed;
  top: 0;
  left: -100%;
  z-index: 1;
  transition: 0.5s;
}
.sidebar.active{
  left: 0;
}
.sd-header{
  padding: 15px;
}
.Xclose{
  float:right;
}
.sidebar-overlay{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.4);
  transition: 0.5s;
  opacity: 0;
  visibility: hidden;
}
.sidebar-overlay.active{
  opacity: 1;
  visibility: visible;
}
.sd-body{
  padding:  10px 35px;
  max-height: calc(100vh - 67px);
  overflow-x: hidden;
}
.sd-body ul{
  display: inline-block;
  width: 100%;
  margin-bottom: 0;
  padding: 0;
}
.sd-body ul li{
  list-style: none;
  margin-bottom: 8px;
}
.sd-body ul li .sd-link{
  display: inline-block;
  width: 100%;
  padding: 10px 16px;
  color: #475f7b;
  background-color: #e5e8ec;
  border-radius: 4px;
  cursor: pointer;
  text-decoration: none;
}